import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import courieroLogo from '../Assets/couriero-logo.png';

const Footer = () => {
  const [newsLetterEmail, setNewsLetterEmail] = useState('');

  return (
    <footer className="bg-[#18264B] flex  flex-col sticky bottom-0 z-0  text-white py-10 px-5 md:px-20">
      <div className="container  flex w-full lg:flex-row md:flex-row flex-col   gap-10 md:gap-5 lg:gap-5">
        
        <div className='flex  flex-col '>

          <img src={courieroLogo} alt="Couriero Logo" className="w-48 ml-3 bg-white p-2 mt-2 rounded-full mb-4" />
          <div className='flex flex-col pl-3 gap-4'>
          <div className='flex flex-row gap-2 pt-5 items-center'>
            <i className="fa-solid text-[#DF8113]  fa-envelope"></i>
            <p className="text-gray-300  font-light text-sm ">support@couriero.in</p>
          </div>
          <div className='flex flex-row gap-2 '>
            <i className="fa-solid  text-[#DF8113]  fa-location-dot"></i>
            <div className='flex flex-col gap-1'>
            <p className="text-gray-300 font-light w-[15rem]  text-sm">6th Floor, Bhamashah Techno Hub, Sansthan Marg, Nagar, Jaipur  302017 Malviya  </p>
            </div>
          </div>
          </div>
        </div>

        
        <div className="flex w-full lg:pl-14 md:pl-14 pl-3   gap-10 ">
          <div className='flex flex-col '>
            <h3 className="font-medium text-base mb-3">Company</h3>
            <ul className="space-y-1 text-gray-300 font-light text-sm gap-2 flex flex-col">
              <li><Link to="/about-us">About Us</Link></li>
              <li><Link to="/careers">Careers</Link></li>
              <li><Link to="/press-release">Press Release</Link></li>
              <li><Link to="/our-coverage">Our Coverage</Link></li>
              <li><Link to="/popular-services">Popular Services</Link></li>
            </ul>
          </div>
          <div>
            <h3 className="font-medium text-base  mb-3">Support & Policies</h3>
            <ul className="space-y-1 text-gray-300 font-light text-sm gap-2 flex flex-col ">
              <li><Link to="/contact-us">Contact Us</Link></li>
              <li><Link to="/refund-policy">Refund Policy</Link></li>
              <li><Link to="/cancellation-policy">Cancellation Policy</Link></li>
              <li><Link to="/terms-and-conditions">Terms and Conditions</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>
        </div>

        
        <div className='flex flex-col pl-3 lg:pl-0 md:pl-0 w-full'>
          <h3 className="font-medium text-base  mb-3">Join our Newsletter</h3>
          <p className="text-gray-300 font-light text-sm ">Subscribe to our newsletter to get our</p>
          <p className="text-gray-300 font-light text-sm mb-3">news & deals delivered to you.</p>
          <div className="relative flex items-center bg-[#C7D3FF] rounded-full  w-[20rem] mt-4">
            <input
              type="email"
              className="w-full text-sm bg-transparent px-4 py-3 text-[#18264B] placeholder-[#546BA6] focus:outline-none rounded-full"
              placeholder="Enter your email address"
              value={newsLetterEmail}
              onChange={(e) => setNewsLetterEmail(e.target.value)}
            />
            <button className="bg-white  text-[#18264B] text-sm whitespace-nowrap px-4 py-[0.8rem] rounded-full font-medium shadow-md">
              Join Now
            </button>
          </div>
        </div>
      </div>

      <div className="mt-10  flex-col lg:flex-row md:flex-row px-4 border-t border-gray-500 pt-5 flex justify-between text-gray-400 text-sm">
        <p>© 2025 Soft-Innov Technologies Pvt. Ltd. All Rights Reserved</p>
        <div className="flex pt-4 lg:pt-0 md:pt-0 space-x-4">
          <div className='flex justify-center items-center bg-white px-[10px] py-[2px] rounded-full'>
          <a href="https://facebook.com" className="hover:text-blue-600 text-blue-900"><i className="fab fa-facebook-f"></i></a>
          </div>
          <div className='flex justify-center items-center bg-white px-2 py-[4px] rounded-full'>
          <a href="https://twitter.com" className="hover:text-blue-600 text-blue-900"><i className="fab fa-twitter"></i></a>
          </div>
          <div className='flex justify-center items-center bg-white px-2 py-[2px] rounded-full'>
          <a href="https://instagram.com" className="hover:text-blue-600 text-blue-900"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
