import React from "react"
import { Helmet } from 'react-helmet-async'
import sms from "../Assets/PriceList/sms.png"
import calculator from "../Assets/PriceList/calculator.png"
import courier from "../Assets/PriceList/courier.png"
import customersupport from "../Assets/PriceList/customer-support.png"
import honesty from "../Assets/PriceList/honesty.png"
import rate from "../Assets/PriceList/rate.png"
import tracking from "../Assets/PriceList/tracking.png"
import Shippingpartners from "../Assets/PriceList/banner.png"

function Pricing() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Couriero: Ultimate Courier Aggregator Solution for eCommerce Businesses</title>
                <meta name="description" content="Discover seamless courier solutions with Couriero, the leading courier aggregator company. Simplify your logistics with our efficient platform, connecting you to a vast network of reliable courier services. Ship with confidence and convenience, all in one place." />
                <link rel="canonical" href="https://couriero.in/price-list" />
            </Helmet>
            <div className="flex  flex-col  bg-white  mt-20">
                <div className="flex mb-10 2xl:px-40 mt-4 justify-center items-center  flex-row">
                    <div className="flex   gap-3 md:w-1/2 lg:w-1/2 flex-col px-10">
                        <h1 className="flex pl-1 text-slate-600 text-clip font-semibold text-lg">Searching for the Best Shipping Rates?</h1>
                        <h2 className="lg:text-[3.6rem] pb-2 md:text-[3.6rem] text-[2.2rem] leading-[2.6rem] font-semibold tracking-tighter  lg:leading-[4rem] md:leading-[4rem] bg-gradient-to-r bg-clip-text from-blue-800 to-orange-300 text-transparent">Affordable and Transparent Pricing
                        </h2>
                        <h2 className=" text-sm 2xl:text-xl text-justify pt-2">At Couriero, we understand that competitive pricing is essential. Whether you're sending a small parcel or bulk shipments, our price list ensures you get the best rates without compromising on service. </h2>

                        <div>
                            <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
                                <button class="relative lg:text-base md:text-base text-sm  mt-4 pr-11 pl-7 hover:shadow-md border border-blue-900  lg:py-3 py-2  hover:duration-300  bg-blue-900  hover:text-blue-900 font-semibold hover:bg-white  text-white lg:rounded-full md:rounded-full rounded-r-full rounded-tl-full  group">
                                    Sign up for free
                                    <i class="fa-solid fa-arrow-right absolute pl-2 pt-1 transition-transform duration-300 ease-in-out group-hover:translate-x-1"></i>
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="lg:flex justify-center  items-center md:flex hidden  w-1/2">
                        <div className="flex  justify-center  ">
                            <img alt="Shipping partner banner" className="w-full pl-5   max-h-96  max-w-xl" src={Shippingpartners} />
                        </div>
                    </div>
                </div>
                <div className="flex w-full  justify-center items-center flex-col my-12 lg:gap-2 md:gap-2">
                    <h1 className="flex  lg:text-5xl md:text-5xl text-2xl  bg-clip-text bg-gradient-to-r from-blue-600 to-purple-700 text-transparent">Select the option</h1>
                    <h1 className="flex text-blue-900 lg:text-5xl md:text-5xl text-xl font-bold flex-row">that meets your expectations</h1>
                </div>
                <div className="flex 2xl:px-64 lg:px-28 px-10 md:px-28  gap-5 mb-24   w-full items-center justify-center lg:flex-row md:flex-row flex-col">
                    <div className="flex lg:w-1/3 md:w-1/3  px-5 lg:pb-28 md:pb-28 pb-10  rounded-xl bg-white  bg-opacity-60 shadow-2xl border items-start flex-col">
                        <div className="flex border-b w-full border-gray-700">
                            <h3 className="text-xl pb-2 pt-3 pl-2 text-blue-900 font-semibold ">Startup</h3>
                        </div>
                        <div className="flex flex-col gap-2 pl-2 pt-3 ">
                            <h3 className="text-base text-gray-800 font-semibold">Rates Start at</h3>
                            <h3 className="text-sm text-gray-800 font-semibold">Rs.26/500 gm</h3>
                            <h4 className="text-gray-800  text-sm">A basic plan designed for social, small, and medium e-commerce sellers, providing essential features to kick-start their logistics journey with ease.</h4>
                        </div>
                        <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer" aria-label="Sign up for a new account">
                            <div className="flex rounded-b-lg py-6  w-full justify-center items-center">
                                <button className=" text-base shadow-2xl hover:font-semibold hover:bg-blue-900 hover:text-white  hover:border-2  text-blue-900 border-2 font-semibold hover:duration-300  border-blue-900 px-12 rounded-full py-2">Sign Up for free</button>
                            </div>
                        </a>
                        <div className="flex flex-col justify-start items-start gap-2 pl-2 pt-3">
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">Live Email & SMS Notifications</h4>
                            </div>
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">1 E-commerce Channel Integration</h4>
                            </div>
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">Real-time Tracking Updates</h4>
                            </div>
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">24/7 Customer Support</h4>
                            </div>
                        </div>
                    </div>
                    <div className="flex lg:w-1/3 md:w-1/3  px-5 lg:pb-28 md:pb-28 pb-10  rounded-xl bg-white  bg-opacity-60 shadow-2xl border items-start flex-col">
                        <div className="flex border-b w-full border-gray-700">
                            <h3 className="text-xl pb-2 pt-3 pl-2 text-blue-900 font-semibold ">Business Plan</h3>
                        </div>
                        <div className="flex flex-col gap-2 pl-2 pt-3 ">
                            <h3 className="text-base text-gray-800 font-semibold">Rates Start at</h3>
                            <h3 className="text-sm text-gray-800 font-semibold">Rs.24/500 gm</h3>
                            <h4 className="text-gray-800  text-sm">Ideal for growing e-commerce businesses looking for more features and flexibility to expand their logistics operations seamlessly.</h4>
                        </div>
                        <div className="flex rounded-b-lg py-6  w-full justify-center items-center">
                            <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer" aria-label="Sign up for a new account">
                                <button className=" text-base shadow-2xl hover:font-semibold hover:bg-blue-900 hover:text-white  hover:border-2  text-blue-900 border-2 font-semibold hover:duration-300  border-blue-900 px-12 rounded-full py-2">Sign Up for free</button>
                            </a>
                        </div>
                        <div className="flex flex-col gap-2 pl-2 justify-start items-start pt-3">
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">Multiple ECommerce Channel Integration</h4>
                            </div>
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">Priority Support</h4>
                            </div>
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">1 Multi-Courier Integration</h4>
                            </div>
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">Advanced Shipping Rates Calculator</h4>
                            </div>
                        </div>
                    </div>
                    <div className="flex lg:w-1/3 md:w-1/3  px-5 lg:pb-28 md:pb-28 pb-10  rounded-xl bg-white  bg-opacity-60 shadow-2xl border items-start flex-col">
                        <div className="flex border-b w-full border-gray-700">
                            <h3 className="text-xl pb-2 pt-3 pl-2 text-blue-900 font-semibold ">Enterprise Plan</h3>
                        </div>
                        <div className="flex flex-col gap-2 pl-2 pt-3 ">
                            <h3 className="text-base text-gray-800 font-semibold">Rates Start at</h3>
                            <h3 className="text-sm text-gray-800 font-semibold">Rs.20/500 gm</h3>
                            <h4 className="text-gray-800  text-sm"> Designed for large-scale businesses with high-volume shipping needs, offering premium features for enhanced logistics management.</h4>
                        </div>
                        <div className="flex rounded-b-lg py-6  w-full justify-center items-center">
                            <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer" aria-label="Sign up for a new account">
                                <button className=" text-base shadow-2xl hover:font-semibold hover:bg-blue-900 hover:text-white  hover:border-2  text-blue-900 border-2 font-semibold hover:duration-300  border-blue-900 px-12 rounded-full py-2">Sign Up for free</button>
                            </a>
                        </div>
                        <div className="flex flex-col gap-2 pl-2 pt-3 justify-start items-start">
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">Unlimited E-commerce Integrations</h4>
                            </div>
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">Dedicated Account Manager</h4>
                            </div>
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">Access to Lowest Shipping Rates</h4>
                            </div>
                            <div className="flex flex-row gap-2 justify-center items-center">
                                <i className="fa-solid text-xs text-green-700 fa-check" />
                                <h4 className="text-gray-900   text-xs">Custom Reporting and Analytics</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex 2xl:px-60 2xl:py-24 lg:bg-gradient-to-r md:bg-gradient-to-r bg-gradient-to-t from-white to-blue-300  lg:mx-16 md:mx-16 mx-10 lg:px-12 md:px-12 py-6 rounded-xl flex-col">
                    <div className="flex justify-center items-center w-full lg:flex-row md:flex-row flex-col">
                        <div className="flex  lg:w-9/12 md:w-9/12">
                            <h3 className="flex  lg:text-4xl md:text-4xl text-center text-2xl text-blue-900 font-semibold">All options cover the basics you require.</h3>
                        </div>
                        <div className="flex lg:w-3/12 md:w-3/12 mt-5 lg:mt-0 md:mt-0 justify-end items-end">
                            <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
                                <button className="text-blue-900 hover:bg-blue-900 hover:text-white hover:duration-300 font-semibold border border-blue-900 text-sm bg-white py-3 px-7 rounded-full">Sign up to know more</button>
                            </a>
                        </div>
                    </div>
                    <div className="flex  justify-center items-center flex-col">
                        <div className="flex gap-8  lg:flex-row md:flex-row flex-col mt-14 justify-center items-center w-full">
                            <div className="flex justify-center items-center  gap-3 lg:w-1/3 md:w-1/3 flex-col">
                                <img src={sms} className="w-1/6" alt="sms" />
                                <h3 className="font-medium">Live email & sms notifications</h3>
                            </div>
                            <div className="flex justify-center items-center  gap-3 lg:w-1/3 md:w-1/3 flex-col">
                                <img src={courier} className="w-1/6" alt="sms" />
                                <h3 className="font-medium">25+ Courier partners</h3>
                            </div>
                            <div className="flex justify-center items-center  gap-3 lg:w-1/3 md:w-1/3 flex-col">
                                <img src={calculator} className="w-1/6" alt="sms" />
                                <h3 className="font-medium">Shipping Rates Calculator</h3>
                            </div>
                        </div>
                        <div className="flex gap-8  lg:flex-row md:flex-row flex-col mt-8 justify-center items-center w-full">
                            <div className="flex justify-center items-center  gap-3 lg:w-1/3 md:w-1/3 flex-col">
                                <img src={rate} className="w-1/6" alt="sms" />
                                <h3 className="font-medium">Best shipping rates</h3>
                            </div>
                            <div className="flex justify-center items-center  gap-3 lg:w-1/3 md:w-1/3 flex-col">
                                <img src={honesty} className="w-1/6 " alt="sms" />
                                <h3 className="font-medium">5+ channel integrations</h3>
                            </div>
                            <div className="flex justify-center items-center  gap-3 lg:w-1/3 md:w-1/3 flex-col">
                                <img src={customersupport} className="w-1/6" alt="sms" />
                                <h3 className="font-medium">Client support</h3>
                            </div>
                        </div>
                        <div className="flex  lg:flex-row md:flex-row flex-col mt-14 justify-center items-center w-full">
                            <div className="flex justify-center items-center  gap-3 lg:w-1/3 md:w-1/3 flex-col">
                                <img src={tracking} className="w-1/6" alt="sms" />
                                <h3 className="font-medium">Real-time tracking updates</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col bg-gradient-to-r from-blue-300 to-orange-400 border   shadow-lg   my-20">
                    <div className="flex justify-center py-11 gap-5 flex-col items-center">
                        <h3 className="flex lg:text-4xl md:text-4xl text-xl  font-semibold">Get Started for free</h3>
                        <h3 className="flex text-center font-semibold  text-sm">No fees. No minimum sign up period. No credit card required</h3>
                        <div className="justify-center mt-5 flex items-center">
                            <a href='http://app.couriero.in/signup' target='_blank' rel="noopener noreferrer">
                                <button className=" text-blue-900 border-blue-900 border text-sm hover:bg-opacity-80 shadow-lg bg-white font-semibold py-3 px-8 rounded-full">Sign up to know more</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Pricing;